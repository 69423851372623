import React, { FunctionComponent } from "react";
import Translation from "../../Translation";

const YouCannotGetItTab: FunctionComponent = () => {
  return (
    <div className="span-uppercase span-bold px-4">
      <Translation id="you-cannot-get" />
    </div>
  );
};

export default YouCannotGetItTab;
