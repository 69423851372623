import React from "react";
import Article from "../../Article";
import TheVirus from "./TheVirus";
import ChronicHepB from "./ChronicHepB";
import EffectsOnLiver from "./EffectsOnLiver";
import HowItsSpread from "./HowItsSpread";

const WhatIsHepB = () => {
  return (
    <section id="what-is-hep-b" className="main-section">
      <Article>
        <TheVirus />
        <ChronicHepB />
        <EffectsOnLiver />
        <HowItsSpread />
      </Article>
    </section>
  );
};
export default WhatIsHepB;
