import React, { FunctionComponent } from "react";
import Image from "../../../Image";
type IndicatorProps = {
  name: string;
  title: JSX.Element;
  current: string;
  setCurrent: (current: string) => void;
  image: any;
  description?: JSX.Element;
  alt: string;
};
const Indicator: FunctionComponent<IndicatorProps> = ({
  name,
  title,
  current,
  setCurrent,
  image,
  description,
  alt,
}) => {
  const active = current === name;
  return (
    <button
      role="button"
      className={["indicator", active && "active"].filter((x) => x).join(" ")}
      onClick={() => setCurrent(name)}
    >
      <div className="wrapper">
        <Image
          fluid={image.childImageSharp.fluid}
          alt={alt}
          className="icon z-0"
        />
        <div className="circle z-10" />
      </div>
      <div className="indicator-title">{title}</div>
      <div className="description hidden laptop:block">{description}</div>
    </button>
  );
};

export default Indicator;
