import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Button from "../../Button";
import Headline from "../../Headline";
import Translation, { useTranslation } from "../../Translation";
import Symptom from "./Symptom";

const ChronicHepB: FunctionComponent = ({ children }) => {
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      jaundice: file(base: { eq: "circle1.png" }) {
        ...SmallImage
      }
      flu: file(base: { eq: "circle2.png" }) {
        ...SmallImage
      }
      nausea: file(base: { eq: "circle3.png" }) {
        ...SmallImage
      }
      joint: file(base: { eq: "circle4.png" }) {
        ...SmallImage
      }
      stomach: file(base: { eq: "circle5.png" }) {
        ...SmallImage
      }
      appetite: file(base: { eq: "circle6.png" }) {
        ...SmallImage
      }
      tiredness: file(base: { eq: "circle7.png" }) {
        ...SmallImage
      }
      waste: file(base: { eq: "circle8.png" }) {
        ...SmallImage
      }
    }
  `);
  return (
    <div id="chronic-hep-b" className="chapter">
      <Headline>
        <h2>
          <Translation id="chronic-hepb" />
        </h2>
      </Headline>

      <div className="subject a mb-8">
        <div className="sub-section">
          <h2>
            <Translation id="what-happens-if" />
          </h2>
          <p className="strong-green">
            <Translation id="when-someone-is" />
          </p>
          <p className="strong-green">
            <Translation id="a-persons-body" />
          </p>
          <p className="strong-green">
            <Translation id="however-if-a-person" />
          </p>
          <p className="strong-green pt-4">
            <Translation id="hepb-called-silent-killer" />
          </p>
          <h4 className="span-uppercase span-bold span-underline">
            <Translation id="most-people-have-no-symptoms" />
          </h4>
          <ul className="bullet pl-4">
            <li>
              <Translation id="can-still-infect-others" />
            </li>
            <li>
              <Translation id="may-have-liver-damage" />
            </li>
          </ul>
        </div>
        <div className="sub-section">
          <h4>
            <Translation id="important-to-talk-to-doctor" />
          </h4>
          <div className="flex flex-wrap">
            <Symptom image={data.jaundice} alt={t("jaundice-alt")}>
              <Translation id="jaundice" />
              <br />
              <Translation id="jaundice2" />
            </Symptom>
            <Symptom
              image={data.flu}
              alt={t("flu-like") + " " + t("flu-like2")}
            >
              <Translation id="flu-like" />
              <br />
              <Translation id="flu-like2" />
            </Symptom>
            <Symptom
              image={data.nausea}
              alt={t("nausea-vomiting") + " " + t("nausea-vomiting2")}
            >
              <Translation id="nausea-vomiting" />
              <br />
              <Translation id="nausea-vomiting2" />
            </Symptom>
            <Symptom image={data.joint} alt={t("joint-pain")}>
              <Translation id="joint-pain" />
            </Symptom>
            <Symptom image={data.stomach} alt={t("upper-right-stomach")}>
              <Translation id="upper-right-stomach" />
            </Symptom>
            <Symptom
              image={data.appetite}
              alt={t("lack-of-appetite") + " " + t("lack-of-appetite2")}
            >
              <Translation id="lack-of-appetite" />
              <br />
              <Translation id="lack-of-appetite2" />
            </Symptom>
            <Symptom image={data.tiredness} alt={t("tiredness")}>
              <Translation id="tiredness" />
            </Symptom>
            <Symptom
              image={data.waste}
              alt={
                t("light-colored-stools-dark-urine") +
                " " +
                t("light-colored-stools-dark-urine2")
              }
            >
              <Translation id="light-colored-stools-dark-urine" />
              <br />
              <Translation id="light-colored-stools-dark-urine2" />
            </Symptom>
          </div>

          <div className="text-center">
            <Button
              text={t("managing-your-condition")}
              size="large"
              color="green"
              href="/living-with-hep-b/managing-hep-b/"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChronicHepB;
