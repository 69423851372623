import React, { FunctionComponent } from "react";
import Image from "../../Image";

type SymptomProps = {
  image: any;
  alt: string;
};
const Symptom: FunctionComponent<SymptomProps> = ({ image, children, alt }) => {
  return (
    <div className="w-1/2 laptop:w-1/4 mb-4">
      <Image
        fluid={image.childImageSharp.fluid}
        alt={alt}
        width={112}
        className="mx-auto"
      />
      <div className="text-center">{children}</div>
    </div>
  );
};

export default Symptom;
