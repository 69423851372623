import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Headline from "../../Headline";
import TranslatedImage from "../../TranslatedImage";
import Translation, { useTranslation } from "../../Translation";
import WhatIsHepB from "../Videos/WhatIsHepB";

const TheVirus: FunctionComponent = ({ children }) => {
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      bodyLiver: allFile(filter: { base: { eq: "bodyliver.png" } }) {
        nodes {
          relativePath
          ...DesktopImage
        }
      }
      chronicLongTerm: allFile(
        filter: { base: { eq: "chronic-longterm.png" } }
      ) {
        nodes {
          relativePath
          ...MobileImage
        }
      }

      world: allFile(filter: { base: { eq: "world.svg" } }) {
        nodes {
          relativePath
          publicURL
        }
      }
      oneInFour: allFile(filter: { base: { eq: "1-4.png" } }) {
        nodes {
          relativePath
          ...DesktopImage
        }
      }
      oneInTwelve: allFile(filter: { base: { eq: "1-12.png" } }) {
        nodes {
          relativePath
          ...DesktopImage
        }
      }
      cancerAsianAmericans: allFile(filter: { base: { eq: "1a.png" } }) {
        nodes {
          relativePath
          ...DesktopImage
        }
      }
      oneInTen: allFile(filter: { base: { eq: "1-10.png" } }) {
        nodes {
          relativePath
          ...DesktopImage
        }
      }
      cancerAfrica: allFile(filter: { base: { eq: "1b.png" } }) {
        nodes {
          relativePath
          ...DesktopImage
        }
      }
    }
  `);
  return (
    <div id="the-virus" className="chapter">
      <Headline>
        <h1>
          <Translation id="the-virus" />
        </h1>
      </Headline>

      <div className="px-4 relative">
        <h2 className="pt-4">
          <Translation id="worlds-most-common" />
        </h2>
        <div className="laptop:px-8 laptop:w-3/4 z-10 pb-12">
          <div className="sub-section">
            <p className="z-10 relative">
              <Translation id="serious-and-potentially-deadly" />
            </p>
          </div>
          <WhatIsHepB />
        </div>
        <div className="laptop:absolute right-0 bottom-0 mr-8 z-0">
          <TranslatedImage
            images={data.bodyLiver}
            className="px-20 tablet:px-0"
            alt={t("liver-with-hepb")}
            width={370}
          />
        </div>
      </div>
      <hr className="border-green mx-8" />
      <div className="subject b mb-4">
        <div className="sub-section">
          <h2>
            <Translation id="in-the-us-many-people" />
          </h2>
          <TranslatedImage
            images={data.chronicLongTerm}
            width={417}
            className="hidden laptop:block mx-auto"
            alt={t("up-to-2-million")}
          />
          <TranslatedImage
            images={data.chronicLongTerm}
            className="laptop:hidden w-full"
            alt={t("up-to-2-million")}
          />
          <ul className="bullet py-4">
            <li>
              <Translation id="approximately-257-million-worldwide" />
            </li>
            <li>
              <Translation id="in-2015-alone" />
            </li>
          </ul>
        </div>
      </div>
      <hr className="border-green mx-4 laptop:mx-8" />
      <div className="subject c">
        <h2>
          <Translation id="people-born-in-shaded" />
        </h2>
        <div className="sub-section relative">
          <TranslatedImage
            images={data.world}
            alt={t("people-born-in-shaded-alt")}
            width={645}
            className="mx-auto"
          />
          <div className="laptop:absolute laptop:w-48 left-0 bottom-0 text-xs pl-2 pb-2 pt-4 laptop:pt-0">
            <span className="foot-symbol">*</span>
            <Translation id="higher-risk" />
          </div>
        </div>
        <div className="flex flex-col laptop:flex-row my-4 laptop:my-8">
          <ul className="bullet w-full laptop:w-1/2">
            <li className="laptop:pr-8">
              <Translation id="most-foreign-born-people" />
            </li>
            <li className="laptop:pr-8">
              <Translation id="hepb-is-most-common" />
            </li>
          </ul>
          <ul className="bullet w-full laptop:w-1/2">
            <li className="laptop:pr-8">
              <Translation id="over-3-percent" />
            </li>
            <li className="laptop:pr-8">
              <Translation id="in-southeast-asia" />
            </li>
          </ul>
        </div>
        <p className="strong-green pb-8">
          <Translation id="dont-wait" />
        </p>
      </div>
      <hr className="border-green mx-4" />

      <div className="subject d">
        <div className="sub-section">
          <h3>
            <Translation id="in-the-us-chb-most-commonly" />
          </h3>
          <div className="mobile-resize">
            <TranslatedImage
              images={data.oneInFour}
              alt={t("if-left-untreated")}
              width={364}
              className="mx-auto py-8 laptop:py-0"
            />
          </div>
          <div className="flex flex-col laptop:flex-row justify-around laptop:py-8 w-full mobile-resize">
            <TranslatedImage
              images={data.oneInTwelve}
              alt={t("one-in-twelve")}
              width={434}
              className="py-8 laptop:py-0"
            />
            <TranslatedImage
              images={data.cancerAsianAmericans}
              alt={t("top-cause-liver-cancer")}
              width={338}
              className="py-8 laptop:py-0"
            />
          </div>
          <div className="flex flex-col laptop:flex-row justify-around laptop:py-8 w-full mobile-resize">
            <TranslatedImage
              images={data.oneInTen}
              alt={t("one-in-ten")}
              width={309}
              className="py-8 laptop:py-0"
            />
            <TranslatedImage
              images={data.cancerAfrica}
              alt={t("chb-major-cause")}
              width={354}
              className="py-8 laptop:py-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TheVirus;
