import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent, useState } from "react";
import Translation, { useTranslation } from "../../../Translation";
import Indicator from "./Indicator";
import Slide from "./Slide";

const LiverProgression: FunctionComponent = () => {
  const data = useStaticQuery(
    graphql`
      query {
        healthy: file(base: { eq: "liver-healthy.png" }) {
          ...LiverImage
        }
        inflammation: file(base: { eq: "liver-inflammation.png" }) {
          ...LiverImage
        }
        fibrosis: file(base: { eq: "liver-fibrosis.png" }) {
          ...LiverImage
        }
        cirrhosis: file(base: { eq: "liver-cirrhosis.png" }) {
          ...LiverImage
        }
        cancer: file(base: { eq: "liver-cancer.png" }) {
          ...LiverImage
        }

        inflammationIndicator: file(base: { eq: "liver-inflammation.png" }) {
          ...LiverIndicator
        }
        fibrosisIndicator: file(base: { eq: "liver-fibrosis.png" }) {
          ...LiverIndicator
        }
        cirrhosisIndicator: file(base: { eq: "liver-cirrhosis.png" }) {
          ...LiverIndicator
        }
        cancerIndicator: file(base: { eq: "liver-cancer.png" }) {
          ...LiverIndicator
        }
        arrowLeft: file(base: { eq: "arrow-left.svg" }) {
          publicURL
        }
        arrowRight: file(base: { eq: "arrow-right.svg" }) {
          publicURL
        }
      }
    `
  );
  const [current, setCurrent] = useState("healthy");
  const slides = ["healthy", "inflammation", "fibrosis", "cirrhosis", "cancer"];

  function next() {
    setCurrent(slides[(slides.indexOf(current) + 1) % slides.length]);
  }
  function previous() {
    setCurrent(
      slides[(slides.indexOf(current) - 1 + slides.length) % slides.length]
    );
  }
  const hidePrevious = current === "healthy";
  const hideNext = current === "cancer";

  const { t } = useTranslation();
  return (
    <div className="liver-progression">
      <div className="indicators flex justify-between items-start laptop:mx-12 py-4 relative">
        <Indicator
          name="inflammation"
          title={t("inflammation")}
          current={current}
          setCurrent={setCurrent}
          image={data.inflammationIndicator}
          alt={t("inflammation-alt")}
        />
        <Indicator
          name="fibrosis"
          title={t("fibrosis")}
          current={current}
          setCurrent={setCurrent}
          image={data.fibrosisIndicator}
          description={t("scarring")}
          alt={t("fibrosis-alt")}
        />
        <Indicator
          name="cirrhosis"
          title={t("cirrhosis")}
          current={current}
          setCurrent={setCurrent}
          image={data.cirrhosisIndicator}
          description={<Translation id="severe-scarring" />}
          alt={t("cirrhosis-alt")}
        />
        <Indicator
          name="cancer"
          title={t("liver-cancer")}
          current={current}
          setCurrent={setCurrent}
          image={data.cancerIndicator}
          description={<Translation id="chb-major-cause-liver-cancer" />}
          alt={t("liver-cancer-alt")}
        />
        <div className="line-wrapper">
          <div className="line" />
        </div>
      </div>
      <div className="controls">
        <button
          role="button"
          className={["arrow", hidePrevious && "hide"]
            .filter((x) => x)
            .join(" ")}
          onClick={previous}
        >
          <img
            src={data.arrowLeft.publicURL}
            alt="Previous Side"
            className="h-8"
          />
        </button>
        <button
          role="button"
          className={["arrow", hideNext && "hide"].filter((x) => x).join(" ")}
          onClick={next}
        >
          <img
            src={data.arrowRight.publicURL}
            alt="Next Side"
            className="h-8"
          />
        </button>
      </div>
      <div className="slides h-96 tablet:mx-4">
        <Slide
          name="healthy"
          title={t("healthy-liver")}
          image={data.healthy}
          alt={t("healthy-liver-alt")}
          current={current}
        />
        <Slide
          name="inflammation"
          title={t("inflammation")}
          image={data.inflammation}
          current={current}
          alt={t("inflammation-alt")}
        />
        <Slide
          name="fibrosis"
          title={t("fibrosis")}
          image={data.fibrosis}
          current={current}
          description={t("scarring")}
          alt={t("fibrosis-alt")}
        />
        <Slide
          name="cirrhosis"
          title={t("cirrhosis")}
          image={data.cirrhosis}
          current={current}
          description={<Translation id="severe-scarring" />}
          alt={t("cirrhosis-alt")}
        >
          <ul className="bullet laptop:w-1/2 mx-auto text-left text-sm py-4">
            <li>
              <Translation id="fifteen-to-twenty-five" />
            </li>
          </ul>
        </Slide>
        <Slide
          name="cancer"
          title={t("liver-cancer")}
          image={data.cancer}
          current={current}
          description={<Translation id="chb-major-cause-liver-cancer" />}
          alt={t("liver-cancer-alt")}
        />
      </div>
    </div>
  );
};

export default LiverProgression;
