import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Video from "../../Video";

const WhatIsHepB: FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      poster: file(base: { eq: "what-is-hep-b.png" }) {
        publicURL
        ...SmallImage
      }
      videos: allFile(filter: { base: { eq: "What_is_hep_B.mp4" } }) {
        nodes {
          ...VideoQuery
        }
      }
    }
  `);

  return <Video videos={data.videos} poster={data.poster.publicURL} />;
};

export default WhatIsHepB;
