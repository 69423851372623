import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Translation, { useTranslation } from "../../Translation";

const YouCanGetItPanel: FunctionComponent = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      youCanGet: file(base: { eq: "you-can-get.svg" }) {
        publicURL
      }
      born: file(base: { eq: "born.svg" }) {
        publicURL
      }
      heart: file(base: { eq: "heart.svg" }) {
        publicURL
      }
      toiletries: file(base: { eq: "toiletries.svg" }) {
        publicURL
      }
      bleed: file(base: { eq: "bleed.svg" }) {
        publicURL
      }
      needle: file(base: { eq: "needle.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <div>
      <div className="flex flex-col laptop:flex-row">
        <ul className="w-full laptop:w-1/2">
          <li className="flex my-2">
            <img
              src={data.born.publicURL}
              className="w-12 laptop:w-20 flex-shrink-0 mr-4 self-start"
            />
            <Translation id="if-you-were-born-in" />
          </li>
          <li className="flex my-2">
            <img
              src={data.heart.publicURL}
              className="w-12 laptop:w-20 flex-shrink-0 mr-4"
            />
            <Translation id="by-exchanging-bodily-fluids" />
          </li>
          <li className="flex my-2">
            <img
              src={data.toiletries.publicURL}
              className="w-12 laptop:w-20 flex-shrink-0 mr-4"
            />
            <Translation id="by-sharing-items" />
          </li>
        </ul>
        <ul className="w-full laptop:w-1/2  laptop:pl-8">
          <li className="flex my-2">
            <img
              src={data.bleed.publicURL}
              className="w-12 laptop:w-20 flex-shrink-0 mr-4"
            />
            <Translation id="from-infected-blood" />
          </li>
          <li className="flex my-2">
            <img
              src={data.needle.publicURL}
              className="w-12 laptop:w-20 flex-shrink-0 mr-4"
            />
            <Translation id="by-sharing-needles" />
          </li>
        </ul>
      </div>
      <div className="my-4">
        <img
          src={data.youCanGet.publicURL}
          alt={t("how-you-can")}
          className="w-full"
        />
        <div className="text-center text-red py-8">
          <Translation id="hepb-positive-person" />
        </div>
      </div>
    </div>
  );
};

export default YouCanGetItPanel;
