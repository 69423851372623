import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Button from "../../Button";
import Headline from "../../Headline";
import Tabcordion from "../../Tabcordion/Tabcordion";
import TranslatedImage from "../../TranslatedImage";
import Translation, { useTranslation } from "../../Translation";
import YouCanGetItPanel from "./YouCanGetItPanel";
import YouCanGetItTab from "./YouCanGetItTab";
import YouCannotGetItPanel from "./YouCannotGetItPanel";
import YouCannotGetItTab from "./YouCannotGetItTab";

const HowItsSpread: FunctionComponent = ({ children }) => {
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      bodyLiver: allFile(filter: { base: { eq: "bodyliver.png" } }) {
        nodes {
          relativePath
          ...DesktopImage
        }
      }
    }
  `);
  return (
    <div id="how-the-virus-is-spread" className="chapter">
      <Headline>
        <h2>
          <Translation id="how-virus-spread" />
        </h2>
      </Headline>

      <div className="subject a">
        <div className="sub-section">
          <h3>
            <Translation id="how-do-you-get" />{" "}
            <Translation id="the-only-way" />
          </h3>
          <Tabcordion
            tabs={[<YouCanGetItTab />, <YouCannotGetItTab />]}
            panels={[<YouCanGetItPanel />, <YouCannotGetItPanel />]}
            initialPanel={0}
            expandBreakpoint=""
            className="tabcordion--spread"
          />
          <div className="text-center pt-8 pb-16">
            <Button
              text={t("abcs-of-hepatitis")}
              color="green"
              size="large"
              href="/resources/abcs-of-hepatitis"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default HowItsSpread;
