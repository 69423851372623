import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Headline from "../../Headline";
import TranslatedImage from "../../TranslatedImage";
import Translation, { useTranslation } from "../../Translation";
import Image from "../../Image";
import LiverProgression from "./LiverProgression/LiverProgression";

const EffectsOnLiver: FunctionComponent = ({ children }) => {
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      bodyLiver: file(base: { eq: "bodyliver-b.png" }) {
        ...DesktopImage
      }
    }
  `);
  return (
    <div id="effects-on-the-liver" className="chapter">
      <Headline>
        <h2>
          <Translation id="effects-on-liver" />
        </h2>
      </Headline>

      <div className="px-4">
        <div className="h2 tablet:hidden">
          <Translation id="liver-is-vital" />
        </div>

        <div className="sub-section">
          <div className="flex flex-row-reverse tablet:flex-row items-center">
            <div className="tablet:mx-16 self-end">
              <div className="mobile-resize w-32 tablet:w-auto -mr-2 tablet:mr-0">
                <Image
                  fluid={data.bodyLiver.childImageSharp.fluid}
                  alt={t("liver-is-vital")}
                  width={200}
                />
              </div>
            </div>
            <div>
              <div className="h2 hidden tablet:block">
                <Translation id="liver-is-vital" />
              </div>
              <h3>
                <Translation id="liver-is-largest" />
              </h3>

              <ul className="bullet">
                <li>
                  <Translation id="processes-what-you-eat-drink" />
                </li>
                <li>
                  <Translation id="fights-off-infection" />
                </li>
                <li>
                  <Translation id="removes-harmful-chemicals" />
                </li>
                <li>
                  <Translation id="makes-substances-that-help" />
                </li>
              </ul>
            </div>
          </div>
          <hr className="border-green " />
        </div>
      </div>
      <div className="subject b healthy mb-12">
        <div className="sub-section">
          <h3>
            <Translation id="chb-can-be-slowly-causing-damage" />
          </h3>
          <LiverProgression />
        </div>
      </div>
    </div>
  );
};

export default EffectsOnLiver;
