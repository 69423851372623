import React, { FunctionComponent } from "react";
import Image from "../../../Image";

type SlideProps = {
  name: string;
  title: JSX.Element;
  image: any;
  alt?: string;
  current: string;
  description?: JSX.Element;
};
const Slide: FunctionComponent<SlideProps> = ({
  name,
  title,
  image,
  alt,
  current,
  children,
  description,
}) => {
  const active = current === name;
  return (
    <div className={["slide", active && "active"].filter((x) => x).join(" ")}>
      <div className="slide-body relative">
        <div
          className="mx-auto my-8"
          style={{ maxWidth: "550px", width: "100%" }}
        >
          <Image fluid={image.childImageSharp.fluid} alt={alt} />
        </div>
        <div className="absolute bottom-0 w-full">
          <div className="slide-title">{title}</div>
          <div className="h-8 tablet:hidden text-center py-2 leading-none">
            {description}
          </div>
          <div className="h-32">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Slide;
