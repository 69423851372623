import React, { FunctionComponent } from "react";
import Translation from "../../Translation";

const YouCanGetItTab: FunctionComponent = () => {
  return (
    <div className="span-uppercase span-bold">
      <Translation id="you-can-get" />
    </div>
  );
};

export default YouCanGetItTab;
