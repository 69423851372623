import React, { FunctionComponent } from "react";
import Layout from "../components/Layout/Layout";
import SEO from "../components/seo";
import WhatIsHepB from "../components/Markup/WhatIsHepB/WhatIsHepB";
import { WildcardRouteProps } from "../../types";

const childRoutes = [
  "the-virus",
  "chronic-hep-b",
  "effects-on-the-liver",
  "how-the-virus-is-spread",
];

const WhatIsHepBPage: FunctionComponent<WildcardRouteProps> = ({
  "*": slug,
}) => {
  return (
    <Layout slug={slug} childRoutes={childRoutes}>
      <SEO name={slug || "what-is-hep-b"} baseName="what-is-hep-b" />
      <WhatIsHepB />
    </Layout>
  );
};
export default WhatIsHepBPage;
