import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Translation, { useTranslation } from "../../Translation";

const YouCannotGetItPanel: FunctionComponent = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      youCannotGet: file(base: { eq: "you-cannot-get.svg" }) {
        publicURL
      }
      touching: file(base: { eq: "touching.svg" }) {
        publicURL
      }
      kiss: file(base: { eq: "kiss.svg" }) {
        publicURL
      }
      alcohol: file(base: { eq: "alcohol.svg" }) {
        publicURL
      }
      food: file(base: { eq: "food.svg" }) {
        publicURL
      }
      sneeze: file(base: { eq: "sneeze.svg" }) {
        publicURL
      }
      utensil: file(base: { eq: "utensil.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <div>
      <ul className="flex flex-wrap">
        <li className="flex items-center my-2 laptop:pr-4 w-full laptop:w-1/2 desktop:w-1/3">
          <img
            src={data.touching.publicURL}
            className="w-12 laptop:w-20 flex-shrink-0 mr-4 self-start"
          />
          <Translation id="touching" />
        </li>
        <li className="flex items-center my-2 desktop:laptop:pr-4 w-full laptop:w-1/2 desktop:w-1/3">
          <img
            src={data.kiss.publicURL}
            className="w-12 laptop:w-20 flex-shrink-0 mr-4"
          />
          <Translation id="kissing" />
        </li>
        <li className="flex items-center my-2 laptop:pr-4 w-full laptop:w-1/2 desktop:w-1/3">
          <img
            src={data.alcohol.publicURL}
            className="w-12 laptop:w-20 flex-shrink-0 mr-4"
          />
          <Translation id="drinking-alcohol" />
        </li>
        <li className="flex items-center my-2 laptop:pr-4 w-full laptop:w-1/2 desktop:w-1/3">
          <img
            src={data.food.publicURL}
            className="w-12 laptop:w-20 flex-shrink-0 mr-4"
          />
          <Translation id="food-water-air" />
        </li>
        <li className="flex items-center my-2 laptop:pr-4 w-full laptop:w-1/2 desktop:w-1/3">
          <img
            src={data.sneeze.publicURL}
            className="w-12 laptop:w-20 flex-shrink-0 mr-4"
          />
          <Translation id="casual-contact" />
        </li>
        <li className="flex items-center my-2 laptop:pr-4 w-full laptop:w-1/2 desktop:w-1/3">
          <img
            src={data.utensil.publicURL}
            className="w-12 laptop:w-20 flex-shrink-0 mr-4"
          />
          <Translation id="sharing-cups" />
        </li>
      </ul>
      <div className="my-4">
        <img
          src={data.youCannotGet.publicURL}
          alt={t("how-you-cannot")}
          className="w-full"
        />
        <div className="text-center text-red py-8">
          <Translation id="hepb-positive-person" />
        </div>
      </div>
    </div>
  );
};

export default YouCannotGetItPanel;
